import classes from './styles.module.css';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import CompanyRegisterImg from "src/assets/images/company_register.avif";

import { Link } from 'react-router-dom';



const CompanyRQ = () => {
    const { t } = useTranslation(['home']);

    return (
        <Element name="CompanyRequirements" className={`${classes.companyRequirements}`}>
            <div className='container'>
                <div className={`row align-items-center ${classes.companyRequirements_con}`}>
                    <div className='col-lg-5'>
                        <div className={classes.img__con}>
                            <img src={CompanyRegisterImg}
                                alt={t("company_requirements.title.lg")}
                                width={460} height={240}
                                loading='lazy' />
                        </div>
                    </div>
                    <div className='col-lg-6 mx-auto'>
                        <h2 className={classes.title}>
                            {t("company_requirements.title")}
                        </h2>
                        <p className={classes.text}>
                            {t("company_requirements.text")}
                        </p>
                        <div className={classes.btn__con}>
                            <Link to='/companies/national/requirements/' className={`${classes.CompanyRequirementsBtn} bttn`} id='secondary'>
                                {t('company_requirements.nt_btn')}
                            </Link>
                            <Link to='/companies/foreign/requirements/' className={`${classes.CompanyRequirementsBtn} bttn`} id='primary'>
                                {t('company_requirements.fn_btn')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default CompanyRQ;