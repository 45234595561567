import { useState, useEffect } from 'react';
import NavLg from './navLg/NavLg';
import NavSm from './navSm/NavSm';


const MyNavbar = () => {
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return windowWidth < 1200 ? (
        <NavSm />
    ) : (
        <NavLg />
    );
};

export default MyNavbar;