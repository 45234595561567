import styles from './styles.module.css';

import { useTranslation } from 'react-i18next';
import { IoMdCheckmark } from "react-icons/io";
import useScrollToTop from 'src/hooks/useScrollToTop';

const RegisterDone = () => {
    const { t } = useTranslation(['companies']);

    useScrollToTop();

    return (
        <div className={styles.con}>
            <div className={styles.card}>
                <div className={styles.successIcon}>
                    <IoMdCheckmark size={85} />
                </div>
                <div className={styles.title}>
                    {t("registerDone.title")}
                </div>
                <div className={styles.message}>
                    {t("registerDone.message")}
                </div>
            </div>
        </div>
    );
};

export default RegisterDone;
