import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './setup/i18n/i18n';
import 'src/assets/styles/global.css';

import { default as routes } from 'src/routes/Routes';
import { isRTL } from './utils/global';

const App = () => {
	return (
		<>
			<ToastContainer className={isRTL() ? "rtl_toast" : ""} stacked position={isRTL() ? 'top-right' : 'top-left'} />
			<Router>
				<Routes>
					{routes.map((route, index) => (
						<Route key={`layout__${index}`} path={route.path} element={<route.element />}>
							{route.routes.map((nestedRoute, nestedIndex) => (
								<Route
									key={`route__${nestedIndex}`}
									path={nestedRoute.path}
									element={<nestedRoute.element />}
								/>
							))}
						</Route>
					))}
				</Routes>
			</Router>
		</>
	);
}

export default App;
