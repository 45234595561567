
import classes from './styles.module.css';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaPhone, FaEnvelope } from "react-icons/fa6";

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';



const Footer = () => {
    const { i18n, t } = useTranslation(['common']);

    const Links = [
        {
            name: t("footer.links.rg_nt"),
            to: "/companies/national/registration/"
        },
        {
            name: t("footer.links.rg_fn"),
            to: "/companies/foreign/registration/"
        },
        {
            name: t("footer.links.rq_nt"),
            to: "/companies/national/requirements/"
        },
        {
            name: t("footer.links.rq_fn"),
            to: "/companies/foreign/requirements/"
        }
    ]

    const copyToClipBoard = async (copyMe: string) => {
        try {
            await navigator.clipboard.writeText(copyMe);
            return true;
        } catch (err) {
            return false
        }
    };

    const phoneCopyHandler = async (phoneNumber: string) => {
        const copied = await copyToClipBoard(phoneNumber);
        if (copied) {
            const text = i18n.language === 'ar' ? 'تم النسخ 👍' : 'Copied 👍'
            toast(text, {
                style: { width: 120 },
                position: i18n.language === 'ar' ? "top-right" : "top-left",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        } else {
            console.log("Error");
        }
    }

    return (
        <section className={classes.footer}>
            <div className='container'>
                <div className={classes.contact}>
                    <div className={classes.location}>
                        <h4>
                            {t("footer.location")}
                        </h4>
                        <div>
                            <iframe title="GCC Location" width="370" height="227" allowFullScreen
                                src="https://maps.google.com/maps?q=32.88239376901088, 13.190283169318016&hl=ar&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                        </div>
                    </div>


                    <div className={classes.list}>
                        <h4>
                            {t("footer.links.title")}
                        </h4>
                        <ul>
                            {Links.map((item, index) => {
                                return <li key={`links-key-${index}`}>
                                    <Link className={classes.link} to={item.to}>
                                        {item.name}
                                    </Link>
                                </li>
                            })}
                        </ul>
                    </div>

                    <div className={classes.list}>
                        <h4>
                            {t("footer.contact_info")}
                            <div className='underline'>
                                <div>
                                </div>
                            </div>
                        </h4>
                        <ul>
                            <li>
                                <span className={classes.link} onClick={() => phoneCopyHandler("+218213605138")}>
                                    <div>
                                        <FaPhone />
                                    </div>
                                    {i18n.language.includes('ar') ? " 5138 360 21 218+" : "+218 21 360 5138"}
                                </span>
                            </li>
                            <li>
                                <a className={classes.link} href="mailto:info@tameer.ly">
                                    <div>
                                        <FaEnvelope />
                                    </div>
                                    info@tameer.ly
                                </a>
                            </li>
                            <li>
                                <a className={classes.link} href="https://www.facebook.com/profile.php?id=100063820852296&mibextid=LQQJ4d">
                                    <div>
                                        <FaFacebookF />
                                    </div>
                                    صفحة الفيسبوك

                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.copyright}>
                    <div className='text-center'>
                        {t("footer.copyright")}
                    </div>

                </div>
            </div>


        </section >
    )
}

export default Footer;