import { BASE_API_URL } from "src/constants/vars";
import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';

import i18n from "src/setup/i18n/i18n";

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers) => {
        headers.set('Accept-Language', i18n.language);
        return headers
    }
})


const apiSlice = createApi({
    baseQuery: baseQuery,
    endpoints: (builder) => ({

    })
})

export default apiSlice;

