import { About, CompaniesRG, CompaniesRQ } from './components';
import Hero from './components/hero/Hero';


export default function () {
    return (<div>
        <Hero />
        <About />
        <CompaniesRG />
        <CompaniesRQ />
    </div>
    )
}

