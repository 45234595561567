import Spinner from 'react-bootstrap/Spinner';
import classes from "./styles.module.css";


interface LoadingPageProp {
    styles?: { [key: string]: string }
    extraClasses?: string
}

const LoadingPage = ({ styles, extraClasses }: LoadingPageProp) => {
    return (
        <div className={classes.spinner__con}>
            <Spinner className={`${classes.spinner} ${extraClasses}`} animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default LoadingPage