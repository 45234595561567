import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';



import {
    HiMenu
} from "react-icons/hi";


import classes from './styles.module.css';
import { NavLink } from 'react-router-dom';
import Logo from 'src/assets/images/nav_logo.png';
import { isRTL } from 'src/utils/global';


const ICON_SIZE = 22


export default () => {
    const { t, i18n } = useTranslation(['common']);


    const getLinkClassName = ({ isActive }: { isActive: boolean }) =>
        `${classes.item__link} nav-link ${isActive ? classes.active : ''}`;

    const langToggleHandle = () => {
        i18n.changeLanguage(i18n.language.includes('ar') ? 'en' : 'ar');
    }


    return (
        <header>
            <Navbar key={'xl'} fixed="top" expand={false} className={`${classes.nav} mb-3 ${classes.nav__sm} ${isRTL() && classes.rtl}`}>
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img src={Logo} alt={t("nav.title")} className={classes.logo_img} width={56} height={56} />
                    </Navbar.Brand>

                    <Navbar.Toggle className={classes.toggle} aria-controls={`offcanvasNavbar-expand-xl`} >
                        <HiMenu size={ICON_SIZE + 4} />
                    </Navbar.Toggle>

                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-xl`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                        placement="end"
                        className={`${classes.sm__offcanvas} ${isRTL() && classes.rtl__offcanvas}`}
                        style={{ direction: 'rtl' }}
                    >
                        <Offcanvas.Header closeButton className={classes.offcanvas__header}>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                                <img src={Logo} alt={t("store_name")} height={50} width={123} />
                            </Offcanvas.Title>
                        </Offcanvas.Header>

                        <Offcanvas.Body className={classes.offcanvas__body}>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <NavLink
                                    to="/"
                                    className={getLinkClassName} >
                                    {t("sm_nav.home")}
                                </NavLink>
                                <NavLink
                                    to="/companies/national/registration/"
                                    className={getLinkClassName}
                                >
                                    {t("sm_nav.companies_registration.national")}
                                </NavLink>
                                <NavLink
                                    to="/companies/foreign/registration/"
                                    className={getLinkClassName}
                                >
                                    {t("sm_nav.companies_registration.foreign")}
                                </NavLink>
                                <NavLink
                                    to="/companies/national/requirements/"
                                    className={getLinkClassName}
                                >
                                    {t("sm_nav.companies_requirements.national")}
                                </NavLink>
                                <NavLink
                                    to="/companies/foreign/requirements/"
                                    className={getLinkClassName}
                                >
                                    {t("sm_nav.companies_requirements.foreign")}
                                </NavLink>
                                <NavLink className={`${classes.item__link} nav-link ${({ isActive }: { isActive: boolean }) => (isActive ? classes.active : "")}`} to="/companies/forms/">
                                    {t("nav.forms")}
                                </NavLink>
                                <div className={`${classes.item__link} ${classes.lang__btn}  nav-link`} onClick={langToggleHandle}>
                                    {i18n.language.includes('ar') ? "EN" : "AR"}
                                </div>

                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            <div className={classes.prop}>
            </div>
        </header >
    );
}

