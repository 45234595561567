import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-http-backend';


import common_en from 'src/setup/i18n/locales/en/common.json';
import common_ar from 'src/setup/i18n/locales/ar/common.json';
import home_en from 'src/setup/i18n/locales/en/home.json';
import home_ar from 'src/setup/i18n/locales/ar/home.json';
import companies_en from 'src/setup/i18n/locales/en/companies.json';
import companies_ar from 'src/setup/i18n/locales/ar/companies.json';
import req_en from 'src/setup/i18n/locales/en/requiremetns.json';
import req_ar from 'src/setup/i18n/locales/ar/requirements.json';



i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'ar',
        supportedLngs: ["en", "ar"],
        resources: {
            en: {
                common: common_en,
                home: home_en,
                companies: companies_en,
                requirements: req_en
            },
            ar: {
                common: common_ar,
                home: home_ar,
                companies: companies_ar,
                requirements: req_ar
            }
        },
        ns: ['common', 'home', 'requirements'],
        defaultNS: 'common',
        // debug: process.env.NODE_ENV === "development",

    });


export default i18n;