import { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Nav from './nav/Nav';
import Footer from './footer/Footer';
import LoadingPage from 'src/pages/loading/Loading';
import { isRTL } from 'src/utils/global';
import classes from "./styles.module.css";
import { useTranslation } from 'react-i18next';


const MainLayout = () => {
    const { i18n } = useTranslation();
    useEffect(() => {

    }, [i18n.language]);

    return (
        <div className={isRTL() ? ["rtl", classes.rtl].join(' ') : ''}>
            <Nav />
            <main>
                <Suspense fallback={<LoadingPage />}>
                    <Outlet />
                </Suspense>
            </main>
            <Footer />
        </div>
    );
}
export default MainLayout;
