import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';


export interface CartItemType {
    img: string,
    name: string,
    slug: string,
    price: string,
    disc_price: string;
    qty: number
}

export interface CartState {
    items: CartItemType[],
    loading: boolean
}

const initialState: CartState = {
    items: [],
    loading: false
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (
            state,
            { payload: { item } }: PayloadAction<{ item: CartItemType }>
        ) => {
            state.items = [...state.items, item];
        },
        removeFromCart: (state, { payload: { slug } }: PayloadAction<{ slug: string }>) => {
            state.items = state.items.filter((item) => item.slug !== slug)
        },
        updateCartItems: (state, { payload: { items } }: PayloadAction<{ items: CartItemType[] }>) => {
            state.items = items;
        },
        cartReset: (state) => {
            state.items = [];
            state.loading = false;
        },
        setLoadingTrue: (state) => {
            state.loading = true;
        },
        setLoadingFalse: (state) => {
            state.loading = false;
        }
    },
})

// Action creators are generated for each case reducer function
export const { addToCart, removeFromCart, updateCartItems,
    setLoadingTrue, setLoadingFalse, cartReset
} = cartSlice.actions

export default cartSlice.reducer;

export const cartSelector = (state: RootState) => state.cart;
export const cartLoadingSelector = (state: RootState) => state.cart.loading;



export const cartitemsSelector = createSelector(
    [cartSelector],
    (cart) => cart.items
);



// Selector to calculate the total price
export const cartTotalSelector = createSelector(
    [cartSelector],
    (cart) => {
        const subTotal = cart.items.reduce((acc, item) => {
            return acc + parseFloat(item.price) * item.qty;
        }, 0);
        const discTotal = cart.items.reduce((acc, item) => {
            const price = item.disc_price ? parseFloat(item.disc_price) : parseFloat(item.price);
            return acc + price * item.qty;
        }, 0);
        return { subTotal, discTotal };
    }
);


export const cartCountSelector = createSelector(
    [cartSelector],
    (cart) => {
        return cart.items.length; // Formats the total to two decimal places
    }
);

