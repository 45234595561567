import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { Element } from 'react-scroll';
import Logo from "src/assets/images/logo.png";


const About = () => {
    const { t } = useTranslation(['home']);
    return (
        <Element name="aboutUs" className={classes.about}>
            <div className='container'>
                <div className="row" >
                    <div className='col-lg-5'>
                        <div className={classes.item__img}>
                            <img src={Logo} loading='lazy'
                                height={380} width={380}
                                alt={t('about.title')} />
                        </div>
                    </div>
                    <div className='col-lg-7'>
                        <div className={classes.item__content}>
                            <h3>
                                {t("about.title")}
                            </h3>
                            <p>
                                {t("about.text")}
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </Element>
    )
}

export default About;