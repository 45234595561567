import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { Link } from 'react-scroll';

const Hero: React.FC = () => {
    const { t } = useTranslation(['home']);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (wrapperRef.current) {
                const offset = window.scrollY;
                wrapperRef.current.style.backgroundPositionY = `${offset * 0.5}px`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section className={classes.wrapper} ref={wrapperRef}>
            <h1>
                {t("hero.title")}
            </h1>
            <p>
                {t("hero.text")}
            </p>
            <div className={classes.btns__con}>
                <Link
                    to='CompanyRegister'
                    smooth={true}
                    duration={500}
                    className={"bttn"}
                    id='white_bttn'
                >
                    {t("hero.btn_1")}
                </Link>

                <Link
                    to='CompanyRequirements'
                    smooth={true}
                    duration={500}
                    className={"bttn"}
                    id='secondary'
                >
                    {t("hero.btn_2")}
                </Link>
            </div>
        </section>
    );
};

export default Hero;
