import classes from './styles.module.css';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import CompanyRegisterImg from "src/assets/images/company_register.avif";

import { Link } from 'react-router-dom';



const CompanyRG = () => {
    const { t } = useTranslation(['home']);

    return (
        <Element name="CompanyRegister" className={`${classes.companyRegister}`}>
            <div className='container'>
                <div className={'row align-items-center'}>
                    <div className='col-lg-5'>
                        <div className={classes.img__con}>
                            <img src={CompanyRegisterImg}
                                alt={t("company_register.title.lg")}
                                width={460} height={240}
                                loading='lazy' />
                        </div>
                    </div>
                    <div className='col-lg-6 mx-auto'>
                        <h2 className={classes.title}>
                            {t("company_register.title")}
                        </h2>
                        <p className={classes.companyRegister__text}>
                            {t("company_register.text")}
                        </p>
                        <div className={classes.btn__con}>
                            <Link to='/companies/national/registration/' className={`${classes.companyRegisterBtn} bttn`} id='secondary'>
                                {t('company_register.nt_btn')}
                            </Link>
                            <Link to='/companies/foreign/registration/' className={`${classes.companyRegisterBtn} bttn`} id='primary'>
                                {t('company_register.fn_btn')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default CompanyRG;