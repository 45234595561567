import { useTranslation } from 'react-i18next';
import {
    HiMenu, HiHome, HiChevronDown,
    HiOfficeBuilding, HiDocumentText, HiOutlineTranslate
} from "react-icons/hi";


import Container from 'react-bootstrap/Container';
import { NavDropdown, Navbar, Nav } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { Link, NavLink } from 'react-router-dom';

import Logo from "src/assets/images/nav_logo.png";
import classes from './styles.module.css';
import { useCallback } from 'react';



const ICON_SIZE = 22


export default () => {
    const { t, i18n } = useTranslation(['common']);


    const RgsTabTitle = useCallback(() => (
        <div className={classes.dropdown__title}>
            {t("nav.companies_registration.title")}
            <HiChevronDown />
        </div>), []);

    const RequirsTabTitle = useCallback(() => (
        <div className={classes.dropdown__title}>
            {t("nav.companies_requirements.title")}
            <HiChevronDown />
        </div>), []);

    const langToggleHandle = () => {
        i18n.changeLanguage(i18n.language.includes('ar') ? 'en' : 'ar');
    }


    return (
        <header>
            <Navbar key={'xl'} fixed="top" expand={'xl'} className={`${classes.nav} mb-3`}>
                <Container fluid>
                    <Navbar.Brand className={classes.nav__brand} href="/">
                        <img src={Logo} alt={t("nav.title")} className={classes.logo_img} width={56} height={56} />
                    </Navbar.Brand>
                    <Navbar.Toggle className={classes.toggle} aria-controls={`offcanvasNavbar-expand-xl`} >
                        <HiMenu size={ICON_SIZE + 4} />
                    </Navbar.Toggle>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-xl`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton className={classes.offcanvas__header}>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                                <img src={Logo} alt={t("store_name")} />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className={classes.offcanvas__body}>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <NavLink className={`${classes.item__link} nav-link ${({ isActive }: { isActive: boolean }) => (isActive ? classes.active : "")}`} to="/">
                                    {t("nav.home")}
                                </NavLink>
                                <NavDropdown title={<RgsTabTitle />} id="basic-nav-dropdown" className={classes.dropdown__menu}>
                                    <Link to="/companies/national/registration/" data-rr-ui-dropdown-item="" className="dropdown-item">
                                        {t("nav.companies_registration.national")}
                                    </Link>

                                    <Link to="/companies/foreign/registration/" data-rr-ui-dropdown-item="" className="dropdown-item">
                                        {t("nav.companies_registration.foreign")}
                                    </Link>
                                </NavDropdown>
                                <NavDropdown title={<RequirsTabTitle />} id="basic-nav-dropdown" className={classes.dropdown__menu}>
                                    <Link to="/companies/national/requirements/" data-rr-ui-dropdown-item="" className="dropdown-item">
                                        {t("nav.companies_requirements.national")}
                                    </Link>

                                    <Link to="/companies/foreign/requirements/" data-rr-ui-dropdown-item="" className="dropdown-item">
                                        {t("nav.companies_requirements.foreign")}
                                    </Link>
                                </NavDropdown>
                                <NavLink className={`${classes.item__link} nav-link ${({ isActive }: { isActive: boolean }) => (isActive ? classes.active : "")}`} to="/companies/forms/">
                                    {t("nav.forms")}
                                </NavLink>
                                <div className={`${classes.item__link} ${classes.lang__btn} nav-link`} onClick={langToggleHandle}>
                                    {i18n.language.includes('ar') ? "EN" : "AR"}
                                </div>

                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            <div className={classes.prop}>
            </div>
        </header >
    );
}
