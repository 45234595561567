import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store';


export interface AuthState {
    access: string;
    refresh: string;
}

const initialState: AuthState = {
    access: '',
    refresh: ''
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (
            state,
            { payload: { access, refresh } }: PayloadAction<AuthState>
        ) => {
            state.access = access;
            state.refresh = refresh;
        }
        ,
        logOut: (state) => {
            state.access = '';
            state.refresh = '';
        }
    },
})

// Action creators are generated for each case reducer function
export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer;

export const authSelector = (state: RootState) => state.auth;