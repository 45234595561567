import { lazy } from 'react';

import Layout from 'src/layouts/Layout';
import Home from "src/pages/home/Home";
import RegisterDone from 'src/pages/companies/registerDone/RegisterDone';


const NationalCompanies = lazy(() => (import("src/pages/companies/national/National")));
const ForeignCompanies = lazy(() => (import("src/pages/companies/foreign/Foreign")));
const NationalRequirs = lazy(() => (import("src/pages/requirements/national/National")));
const ForeignRequirs = lazy(() => (import("src/pages/requirements/foreign/Foreign")));
const Forms = lazy(() => (import("src/pages/Forms/Forms")));;



const routes = [
    {
        path: '/',
        element: Layout,
        routes: [
            {
                path: '',
                element: Home
            },
            {
                path: 'companies/national/registration/',
                element: NationalCompanies
            },
            {
                path: 'companies/national/registration/success/',
                element: RegisterDone
            },
            {
                path: 'companies/foreign/registration',
                element: ForeignCompanies
            },
            {
                path: 'companies/foreign/registration/success/',
                element: RegisterDone
            },
            {
                path: 'companies/national/requirements/',
                element: NationalRequirs
            },
            {
                path: 'companies/foreign/requirements/',
                element: ForeignRequirs
            },
            {
                path: 'companies/forms/',
                element: Forms
            },
        ]
    }
];

export default routes;
