import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store';


export interface PasswordState {
    public_code: string;
    secret_code: string;
}

const initialState: PasswordState = {
    public_code: '',
    secret_code: ''
}

export const passwordSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {
        setCodes: (
            state,
            { payload: { public_code, secret_code } }: PayloadAction<PasswordState>
        ) => {
            state.public_code = public_code;
            state.secret_code = secret_code;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setCodes } = passwordSlice.actions

export default passwordSlice.reducer;

export const passwordSelector = (state: RootState) => state.password;