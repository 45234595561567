import { setupListeners } from '@reduxjs/toolkit/query'
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/auth';
import cartReducer from './reducers/cart';
import apiSlice from './api/apiSlice';
import passwordReducer from './reducers/password'

const store = configureStore({
    reducer: {
        auth: authReducer,
        cart: cartReducer,
        password: passwordReducer,
        [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware)
})


export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch


setupListeners(store.dispatch);